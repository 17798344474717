import React from 'react';
import {useFilter} from "../../FilterContext/FilterContext";
import Dropdown from "../../Forms/Dropdown/Dropdown";

const CourseFilterComponent: React.FC<{options: [string]}>= (props) => {

    const { filters, setFilters } = useFilter();

    const handleCheckboxFilterChange = (filterName: string, value: any, e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            setFilters({ ...filters, [filterName]: [...(filters[filterName] || []), value] });
        } else {
            const updatedValues = (filters[filterName] || []).filter((item: any) => item !== value);
            setFilters({ ...filters, [filterName]: updatedValues });
        }
    };

    return (
        <>
            <div className={"max-sm:w-full"}>
                <Dropdown
                    title={"Course"}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>, course: string) => {
                        handleCheckboxFilterChange("course", course.toLowerCase(), e);
                    }}
                    optionsArray={props.options.map(x => {
                        return x.charAt(0).toUpperCase() + x.slice(1);
                    })}
                />
            </div>
        </>
    );
};

export default CourseFilterComponent;
