import { createSlice } from '@reduxjs/toolkit'

export const recipeSlice = createSlice({
    name: 'recipe',
    initialState: {
        value: null
    },
    reducers: {
        setRecipe: (state, action) => {
            state.value = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { setRecipe } = recipeSlice.actions
export default recipeSlice.reducer