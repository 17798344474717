import React, {FC} from 'react';
import styles from './Recipes.module.css';
import RecipeGrid from "../RecipeGrid/RecipeGrid";
import {Recipe} from "../../models/Recipe";
import {useFilter} from "../FilterContext/FilterContext";
import {filter} from "rxjs";
import {DateTime, Duration} from "luxon";
import recipeTiming from "../RecipeTiming/RecipeTiming";

interface RecipesProps {
    recipes: Recipe[]
}

const Recipes: FC<RecipesProps> = (props) => {
    const defaultUrl = "https://images.unsplash.com/photo-1546069901-ba9599a7e63c?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8fA%3D%3D";
    const baseUrl: string = process.env.REACT_APP_S3_URL ?? "";
    const { filters } = useFilter();
    const filteredRecipes = props.recipes.filter(recipe => {
        for (const filterKey in filters) {
            if (Object.prototype.hasOwnProperty.call(filters, filterKey)) {
                const filterValues = filters[filterKey];
                if (filterKey === 'title') {
                    if (filterValues && filterValues.length > 0 && !recipe
                        .title
                        .toLowerCase()
                        .includes(filterValues)) {
                        return false;
                    }
                }
                if(filterKey === "books") {
                    if (filterValues && filterValues.length > 0 && recipe
                        .userId
                        .includes(filterValues)) {
                        return false;
                    }
                }
                if (filterKey === "time") {
                    if (filterValues.length > 0) {
                        // Check if the recipe's totalTime falls within any of the selected time filter values
                        const matchesTimeFilter = filterValues.some((filterIsoTime: string) => {
                            const recipeTime = Duration.fromISO(recipe.times?.totalTime);
                            const filterValue = Duration.fromISO(filterIsoTime);
                            return recipeTime <= filterValue;
                        });
                        if (!matchesTimeFilter) {
                            return false;
                        }
                    }
                }

                if(filterKey === "course" || filterKey === "category") {
                    if (filterValues.length > 0 && !filterValues.includes((recipe as any)[filterKey].toLowerCase())) {
                        return false;
                    }
                }
            }
        }
        return true;
    });

    return (
        <div data-testid="Recipes">
            <div className={styles.List}>
                {filteredRecipes?.map((recipe) => (
                    <RecipeGrid title={recipe.title}
                                key={recipe.id}
                                id={recipe.id}
                                url={recipe.images?.length > 0
                                    ? baseUrl + recipe.images[0].name
                                    : defaultUrl}
                    />
                ))}
            </div>
        </div>
    );
}

export default Recipes;
