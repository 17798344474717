import {configureStore} from "@reduxjs/toolkit";
import recipeReducer from "./features/recipe/recipeSlice";
import menuReducer from "./features/menu/menuSlice";
import chooseReducer from "./features/recipe/chooseSlice";
import dropdownReducer from "./features/dropdown/dropdownSlice";

export default configureStore({
    reducer: {
        recipe: recipeReducer,
        choose: chooseReducer,
        dropdown: dropdownReducer,
        menu: menuReducer
    },
})

