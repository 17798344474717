import React from 'react';
import {useFilter} from "../../FilterContext/FilterContext";

const TitleFilterComponent: React.FC = () => {

    const { filters, setFilters } = useFilter();

    const handleFilterChange = (filterName: string, value: any) => {
        setFilters({ ...filters, [filterName]: value });
    };

    const handleTitleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const titleValue = e.target.value.toLowerCase();
        handleFilterChange("title", titleValue);
    };

    return (
        <>
            <div className={"max-sm:w-full"}>
                <input
                    className={"shadow appearance-none border rounded w-full py-2 " +
                        "px-3 text-gray-600 leading-tight focus:outline-none focus:shadow-outline"}
                    name={"Title"}
                    placeholder={"Search recipe"}
                    onChange={handleTitleFilterChange}
                />
            </div>
        </>
    );
};

export default TitleFilterComponent;
