import React, {FC, useState} from 'react';

interface InputProps {
    name: string
    placeholder: string
    value: string
    icon?: any
}

const Input: FC<InputProps> = (props) => {

    const [inputValue, setInputValue] = useState(props.value);

    const onChangeHandler = (event:any) => {
        setInputValue(event.target.value);
    };

    return (
        <div className="relative">
            {props.icon ? <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                {props.icon}
            </div> : ""}

            {props.icon
                ? <input className="ps-10 shadow appearance-none border rounded w-full py-2
                     px-3 text-gray-600 leading-tight focus:outline-none focus:shadow-outline"
                         type="text"
                         id={props.name}
                         name={props.name}
                         value={inputValue}
                         onChange={onChangeHandler}
                         placeholder={props.placeholder} />
                : <input className="shadow appearance-none border rounded w-full py-2
                    px-3 text-gray-600 leading-tight focus:outline-none focus:shadow-outline"
                        type="text"
                        id={props.name}
                        name={props.name}
                        value={inputValue}
                        onChange={onChangeHandler}
                        placeholder={props.placeholder} />
            }
        </div>);
}

export default Input;
