import React, {FC, useEffect, useRef, useState} from 'react';
import Input from "../Forms/Input/Forms/Input";
import ShareService from "../../services/ShareService";
import {useAuth0} from "@auth0/auth0-react";
import {AccessDto} from "../../models/AccessDto";

interface FriendInviteProps {
    callback: any,
    members: AccessDto[]
}

const FriendInvite: FC<FriendInviteProps> = (props) => {

    const {getAccessTokenSilently} = useAuth0();
    const token = useRef("");
    const [emptyEmailError, setEmptyEmailError] = useState(false);

    useEffect(() => {
        getAccessTokenSilently().then(result => {
            token.current = result;
        });
    }, [getAccessTokenSilently]);

    const submitInvite = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setEmptyEmailError(false);
        const formData = new FormData(e.currentTarget);
        const email = formData.get("email") as string;
        if (email.trim() !== "") {
            const share = new ShareService();
            share.Invite(token.current, email).then(r => {
                props.callback();
            });
        } else {
            setEmptyEmailError(true);
        }
    }


    return (
        <div className={"text-left"}>
            <div className={"text-left"}>
                <h2 className={"font-nunito text-lg font-bold mb-4"}>Send Invite</h2>
            </div>
            <form onSubmit={(e) => submitInvite(e)}>
                <label htmlFor={"username"} className={"font-bold"}>
                    Enter the e-mail of someone you want to share your recipes with
                </label>
                <div className={"sm:w-3/4 mt-4 mb-4"}>
                    <Input name={"email"} placeholder={"Enter email"} value={""} />
                    {emptyEmailError
                        ? <strong className={"text-red-500"}>E-mail cannot be empty.</strong>
                        : ""
                    }
                </div>
                <button
                    className="flex-inline bg-gray-300 hover:bg-blue-700 text-gray-700 font-bold py-2 px-4 rounded
                focus:outline-none focus:shadow-outline"
                    type="submit"
                > Send invite
                </button>
            </form>
        </div>
    );
}
export default FriendInvite;
