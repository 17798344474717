import LeftMenu from "../components/LeftMenu/LeftMenu";
import PageTitle from "../components/PageTitle/PageTitle";
import {Bird, UsersThree} from "@phosphor-icons/react";
import styles from "../components/PageTitle/PageTitle.module.css";
import React, {useEffect, useRef, useState} from "react";
import FriendInvite from "../components/FriendInvite/FriendInvite";
import OpenInvites from "../components/OpenInvites/OpenInvites";
import ManageShares from "../components/ManageShares/ManageShares";
import ShareService from "../services/ShareService";
import {AccessDto} from "../models/AccessDto";
import {useAuth0} from "@auth0/auth0-react";
import MobileNav from "../components/MobileNav/MobileNav";

export default function SharePage() {

    const {getAccessTokenSilently} = useAuth0();
    const token = useRef("");

    useEffect(() => {
        getAccessTokenSilently().then(result => {
            token.current = result;
            updateMembers();
        });
    }, [getAccessTokenSilently]);

    const [members, setMembers]
        = useState<AccessDto[]>([]);

    const updateMembers = () =>
    {
        console.log("update members called");
        const share = new ShareService();
        share.GetMembers(token.current).then(r => {
            setMembers(r);
        });
    }

    return (
        <div>
            <div className={"sm:hidden inline-block mt-2"}>
                <a href="https://cookbird.cloud"
                   className={"text-white text-2xl font-nunito font-black"}
                   target="_blank" rel="noreferrer">
                    COOK<Bird size={25} className={"inline mt-[-5px] ml-[2px]"}/>BIRD
                </a>
                <hr className={"invisible md:visible"}/>
            </div>
            <div className={"main-container"}>
                <div className={"menu-container"}>
                    <LeftMenu/>
                </div>

                <div className={"container-item"}>
                    <PageTitle
                        title={"FamilyShare"}
                        icon={<UsersThree className={styles.TitleIcon} size={30} weight={"bold"}/>}
                        controls={<></>}
                    />

                    <div className={"break"}></div>

                    <div className={"sm:flex w-full gap-4 max-sm:mx-4"}>
                        <div className={"sm:inline-flex w-full"}>
                            <div className="w-full">
                                <div className={"bg-dark-primary flex flex-col flex-wrap align-items-start text-white" +
                                    " align-items-start shadow-md " +
                                    "rounded-2xl px-8 pt-6 pb-8"}>
                                    <FriendInvite callback={updateMembers} members={members}/>
                                </div>
                            </div>
                        </div>

                        <div className={"sm:inline-flex w-full max-sm:mt-2.5"}>
                            <div className="w-full">
                                <div className={"bg-dark-primary flex flex-col flex-wrap align-items-start text-white" +
                                    " align-items-start shadow-md " +
                                    "rounded-2xl px-8 pt-6 pb-8"}>
                                    <OpenInvites/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"flex w-full max-sm:mx-4"}>
                        <div className={"inline-flex w-full"}>
                            <div className="w-full">
                                <div className={"bg-dark-primary flex flex-col flex-wrap align-items-start text-white" +
                                    " align-items-start shadow-md " +
                                    "rounded-2xl px-8 pt-6 pb-8 mb-4"}>
                                    <ManageShares updateMembers={updateMembers} members={members}/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <MobileNav/>
        </div>
    );
}