import React, {FC, useEffect, useRef, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {AccessDto} from "../../models/AccessDto";
import ShareService from "../../services/ShareService";

interface ManageSharesProps {
    updateMembers: any,
    members: AccessDto[]
}

const ManageShares: FC<ManageSharesProps> = (props) => {

    const {getAccessTokenSilently} = useAuth0();
    const token = useRef("");

    useEffect(() => {
        getAccessTokenSilently().then(result => {
            token.current = result;
        });
    }, [getAccessTokenSilently]);

    const remove = (member: AccessDto) => {
        const share = new ShareService();
        share.RemoveMember(token.current, member.userIdAllowed).then(r => {
            props.updateMembers();
        });
    }

    return (
        <div>
            <div className={"text-left"}>
                <h2 className={"font-nunito text-lg font-bold mb-4"}>Manage Access</h2>
            </div>
            <div className={"grid grid-cols-1 text-left gap-4"}>
            {props.members?.map(member => {
                return (
                    <div key={member.userIdAllowed} className={"flex gap-2"}>
                        <button type="button"
                                onClick={() => remove(member)}
                                className="text-blue-700 border border-red-700 hover:bg-red-700 hover:text-white
                                focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm
                                 p-2.5 text-center items-center dark:border-red-500 dark:text-red-500
                                 dark:hover:text-white dark:focus:ring-red-800 dark:hover:bg-red-500"
                        >
                            <svg className="w-3 h-3 rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="currentColor" viewBox="0 0 18 18">
                                <path
                                    d="M3 7H1a1 1 0 0 0-1 1v8a2 2 0 0 0 4 0V8a1 1 0 0 0-1-1Zm12.954 0H12l1.558-4.5a1.778 1.778 0 0 0-3.331-1.06A24.859 24.859 0 0 1 6 6.8v9.586h.114C8.223 16.969 11.015 18 13.6 18c1.4 0 1.592-.526 1.88-1.317l2.354-7A2 2 0 0 0 15.954 7Z"/>
                            </svg>
                        </button>
                        <div className={"flex self-center"}>
                            <>
                                <b>{member.userIdAllowed}</b>&nbsp;<i>({member.accepted ? "accepted" : "awaiting"})</i>
                            </>
                        </div>
                    </div>)
            }) ?? "Invite friends to manage them ;-)"}
            </div>
        </div>
    );
};

export default ManageShares;
