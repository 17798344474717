import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        value: JSON.parse(localStorage.getItem("menu-state") ?? '{}')
    },
    reducers: {
        toggleMenu: (state) => {
            state.value = !state.value;
            localStorage.setItem("menu-state", state.value.toString());
        }
    },
})

export const { toggleMenu } = menuSlice.actions
export default menuSlice.reducer