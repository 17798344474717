import LeftMenu from "../components/LeftMenu/LeftMenu";
import React, {useEffect} from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import styles from "../components/PageTitle/PageTitle.module.css";
import {ArrowCircleLeft, Bird, Pencil, PlusCircle} from "@phosphor-icons/react";
import SearchByUrl from "../components/SearchByUrl/SearchByUrl";
import RecipeCreateForm from "../components/RecipeCreateForm/RecipeCreateForm";
import {useDispatch, useSelector} from "react-redux";
import MethodChooser from "../components/MethodChooser/MethodChooser";
import {setChooseState} from "../features/recipe/chooseSlice";
import RecipeEditForm from "../components/RecipeEditForm/RecipeEditForm";
import MobileNav from "../components/MobileNav/MobileNav";

export default function CreateRecipePage() {

    const recipe = useSelector((state) => state.recipe);
    const stage = useSelector((stage) => stage.choose);
    const dispatch = useDispatch();

    useEffect(() => {
        if(recipe.value != null) {
            dispatch(setChooseState("edit"));
        }
    }, [recipe]);

    return (
        <div>
            <div className={"sm:hidden inline-block mt-2"}>
                <a href="https://cookbird.cloud"
                   className={"text-white text-2xl font-nunito font-black"}
                   target="_blank" rel="noreferrer">
                    COOK<Bird size={25} className={"inline mt-[-5px] ml-[2px]"}/>BIRD
                </a>
                <hr className={"invisible md:visible"}/>
            </div>

            <div className={"main-container"}>

                <div className={"menu-container"}>
                    <LeftMenu/>
                </div>

                <div className={"container-item"}>
                    <PageTitle
                        title={"Create Recipe"}
                        icon={<PlusCircle className={styles.TitleIcon} size={30} weight={"bold"}/>}
                        controls={""}
                    />
                    <div className={"w-full"}>

                        <div className={"flex flex-col"}>
                            {stage.value !== "choice" ? <div className={"flex"}>
                                <button
                                    className="max-sm:m-4 bg-dark-secondary font-bold text-white
                                    hover:bg-dark-primary py-1.5 mb-2 px-3 rounded-xl
                                inline-flex items-center" onClick={() => dispatch(setChooseState("choice"))}>
                                    <ArrowCircleLeft
                                        size={32}
                                        className={"fill-current w-4 h-4 mr-2"}/>
                                    <span>Go back</span>
                                </button>
                            </div> : ""}

                            {stage.value === "choice" ? <MethodChooser/> : ""}
                            {stage.value === "search" ? <SearchByUrl/> : ""}
                            {stage.value === "blank" ? <RecipeCreateForm/> : ""}
                            {stage.value === "edit" ? <RecipeEditForm recipe={recipe.value}/> : ""}
                        </div>
                    </div>
                </div>
            </div>

            <MobileNav/>
        </div>
    );
}