import React from 'react';
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import {Outlet} from "react-router-dom";
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"

function App() {

    const {isAuthenticated, isLoading, loginWithRedirect} = useAuth0();

    if(!isAuthenticated && !isLoading) {
        loginWithRedirect();
    }

    return (
        <div className="App">
            <SpeedInsights/>
            <Analytics/>
            <Outlet/>
        </div>
    );
}

export default App;
