import {Recipe} from "../models/Recipe";

export default class RecipeService {

    // base
    base: string = process.env.REACT_APP_API_URL ?? "";

    async getRecipes(token:string) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        const response = await fetch(this.base + "Recipe", requestOptions);
        return await response.json() as Recipe[];
    }
    async getRecipe(url: string, token: string) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(this.base + "OutsideRecipe?url=" + url, requestOptions);
    }

    async getRecipeById(id: number, token: string) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(this.base + "Recipe/" + id, requestOptions);
    }

    async deleteRecipe(id: number, token: string) {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        };
        return await fetch(this.base + "Recipe?id=" + id, requestOptions);
    }

    async putRecipe(id: number, recipe: FormData, token: string )
    {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: recipe
        };
        return await fetch(this.base + "Recipe/" + id, requestOptions);
    }

    async postRecipe(recipe:FormData, token: string )
    {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: recipe
        };
        console.log(this.base);
        return await fetch(this.base + "Recipe", requestOptions);
    }
}