import React, { FC } from 'react';
import {Dna, Drop, Fire, Grains} from "@phosphor-icons/react";
import {Nutrition} from "../../models/Nutrition";

interface RecipeNutritionProps {
    nutrition: Nutrition
}

const RecipeNutrition: FC<RecipeNutritionProps> = (props) => (
    <div className={"flex justify-content-start flex-row flex-wrap flex-column py-3 pl-4 w-full sm:rounded-t-xl " +
        "text-white sm:bg-dark-secondary "}>
        <div className={"inline-flex cursor-default"}>
            <Grains color="#fff" className={"w-4 h-4 mt-[3px] text-gray-500"}/>
            <span title="Carbs"
                  className={"ml-[3px] font-nunito"}>
                {props.nutrition?.carbs ?? "0"}
            </span>
        </div>
        <div className={"inline-flex ml-2 cursor-default"}>
            <Drop color="#fff" className={"w-4 h-4 mt-[3px] text-gray-500"}/>
            <span title="Fat"
                  className={"ml-[3px] font-nunito"}>
                {props.nutrition?.fat ?? "0"}
            </span>
        </div>
        <div className={"inline-flex ml-2 cursor-default"}>
            <Dna color="#fff" className={"w-4 h-4 mt-[3px] text-gray-500"}/>
            <span title="Protein"
                  className={"ml-[3px] font-nunito"}>
                {props.nutrition?.protein ?? "0"}
            </span>
        </div>
        <div className={"inline-flex ml-2 cursor-default"}>
            <Fire color="#fff" className={"w-4 h-4 mt-[3px] text-gray-500"}/>
            <span title="Calories"
                  className={"ml-[3px] font-nunito"}>
                {props.nutrition?.calories ?? "0"}
            </span>
        </div>
    </div>
);

export default RecipeNutrition;
