import React, { FC } from 'react';
import {
    CaretCircleDoubleRight,
    Hammer,
    Robot
} from "@phosphor-icons/react";
import {useDispatch} from "react-redux";
import {setChooseState} from "../../features/recipe/chooseSlice";

interface MethodChooserProps {}

const MethodChooser: FC<MethodChooserProps> = () => {

    const dispatch = useDispatch()

    return (
    <div className={"max-sm:pb-[58px]"}>
        <div className="flex flex-row flex-wrap sm:gap-6">

            <div className={"max-sm:m-4 flex flex-col basis-1 flex-1 justify-center bg-dark-primary shadow-md rounded-2xl " +
                "px-8 pt-6 pb-8 mb-4"}>

                <div className={"block"}>

                    <h2 className={"text-white font-bold text-3xl mb-2"}>
                        Create from existing recipe
                    </h2>

                    <p className={"mb-12 text-white w-3/4 inline-flex"}>
                        Found a recipe on the internet? Use AI to copy the recipe into your
                        person cookbook.
                    </p>

                    <div className={"justify-center inline-flex h-[200px] w-[200px] rounded-full " +
                        "mb-14 bg-amber-200 items-center justify-content-center"}>
                        <Robot className={"text-gray-800"} size={80}/>
                    </div>

                    <div className={""}>
                        <button
                            className="inline-flex bg-amber-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4
                            rounded-full items-center" onClick={() => dispatch(setChooseState("search"))}>
                            <span>Create from link</span>
                            <CaretCircleDoubleRight size={32} weight={"fill"} className={"fill-current w-4 h-4 ml-2"}/>
                        </button>
                    </div>
                </div>
            </div>

            <div className={"max-sm:m-4 flex flex-col basis-1 flex-1 justify-center bg-dark-primary " +
                "shadow-md rounded-2xl px-8 pt-6 pb-8 mb-4"}>

                <div className={""}>

                    <h2 className={"text-white font-bold text-3xl mb-2"}>
                        Create from scratch
                    </h2>

                    <p className={"mb-12 text-white w-3/4 inline-flex"}>
                        Have a great idea for a recipe? Create it from scratch using the recipe builder.
                    </p>

                    <div className={"justify-center inline-flex h-[200px] w-[200px] rounded-full " +
                        "mb-14 bg-emerald-200 items-center justify-content-center"}>
                        <Hammer size={80} className={"text-gray-800"}/>
                    </div>

                    <div className={""}>
                        <button
                            className="inline-flex bg-emerald-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4
                            rounded-full items-center" onClick={() => dispatch(setChooseState("blank"))}>
                            <span>Create blank recipe</span>
                            <CaretCircleDoubleRight size={32} weight={"fill"} className={"fill-current w-4 h-4 ml-2"}/>
                        </button>
                    </div>

                </div>

            </div>

        </div>
    </div>)
};

export default MethodChooser;
