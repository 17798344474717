import React, {FC, Fragment, useEffect, useState} from 'react';
import './LeftMenu.scss';
import {
    Bird,
    BookOpen,
    CalendarPlus,
    ClipboardText,
    MagnifyingGlass,
    PlusCircle,
    Power,
    UserCircle, UsersThree
} from "@phosphor-icons/react";
import {useAuth0} from "@auth0/auth0-react";
import {NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toggleMenu} from "../../features/menu/menuSlice";

interface LeftMenuProps {}

const LeftMenu: FC<LeftMenuProps> = () => {
    const {logout, user} = useAuth0();

    const dispatch = useDispatch()
    const menu = useSelector((state:any) => state.menu);

    const [menuClosed, setMenuClosed]
        = useState(JSON.parse(localStorage.getItem("menu-state") ?? "{}"));

    useEffect(() => {
        setMenuClosed(menu.value);
    }, [menu]);

    function clickedLogout() {
        logout().then();
    }

    return (
    <Fragment>
        <div id="nav-bar">
            <input id="nav-toggle" type="checkbox"
                   checked={menuClosed}
                   onChange={() => dispatch(toggleMenu())} />
            <div id="nav-header">
                <a id="nav-title" href="https://cookbird.cloud" target="_blank">COOK
                    <Bird size={25}/>BIRD
                </a>
                <label htmlFor="nav-toggle">
                    <span id="nav-toggle-burger"></span>
                </label>
                <hr className={"invisible md:visible"}/>
            </div>

            <div id={'nav-content'}>
                <NavLink to={"/"} className="nav-button">
                    <BookOpen size={18} weight="duotone"/>
                    <span>My Recipes</span>
                </NavLink>
                <NavLink to={"/recipe/create"} className="nav-button">
                    <PlusCircle size={18} weight="duotone"/>
                    <span>Add Recipe</span>
                </NavLink>
                <hr className={"invisible md:visible"}/>
                <NavLink to={"/3"} className='nav-button'>
                    <CalendarPlus size={18} weight="duotone"/>
                    <span>Mealplan</span>
                </NavLink>
                <NavLink to={"/4"} className='nav-button'>
                    <ClipboardText size={18} weight="duotone"/>
                    <span>Shopping List</span>
                </NavLink>
                <NavLink to={"/5"} className='nav-button'>
                    <MagnifyingGlass size={18} weight="duotone"/>
                    <span>Find recipes</span>
                </NavLink>

                <hr className={"invisible md:visible"}/>

                <NavLink to={"/share"} className={'nav-button'}>
                    <UsersThree size={18} weight="duotone"/>
                    <span>FamilyShare</span>
                </NavLink>

                <NavLink to={"/profile"} className={'nav-button'}>
                    <UserCircle size={18} weight="duotone"/>
                    <span>Profile Settings</span>
                </NavLink>

                <div id="nav-highlight"></div>
            </div>

            <div id={'nav-footer'}>
                <div id={'nav-footer-heading'}>
                    <div id={'nav-footer-avatar'}>
                        <img src={user?.picture}/>
                    </div>
                    <div id={'nav-footer-titlebox'}>
                        <a id={'nav-footer-title'}
                           href="https://cookbird.cloud"
                           target="_blank"> {user?.name}
                        </a>
                        <span id={'nav-footer-subtitle'}>Premium</span>
                    </div>
                    <label htmlFor="nav-footer-toggle" onClick={clickedLogout}>
                        <Power className={"fas"} size={18}/>
                    </label>
                </div>
            </div>
        </div>
    </Fragment>);
};

export default LeftMenu;
