import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {visible} from "../../../features/dropdown/dropdownSlice";

interface DropdownProps {
    title: string
    handleChange: any
    optionsArray: any
}

const Dropdown: FC<DropdownProps> = (props) => {

    const [visibility, setVisibility] = useState("hidden");
    const dispatch = useDispatch();
    const vis = useSelector((state:any) => state.dropdown);

    useEffect(() => {
        if(vis.value !== "" && vis.value !== props.title) {
            setVisibility("hidden");
        }
    }, [vis]);

    const dropdownDefaultCheckbox = () => {
        if(visibility === "hidden") {
            setVisibility("");
            dispatch(visible(props.title))
        } else {
            setVisibility("hidden");
        }
    }

    return (<>
            <button
                onClick={dropdownDefaultCheckbox}
                className={`max-sm:w-full text-white hover:bg-dark-secondary
                font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center
                ${visibility === "hidden" ? 'bg-dark-primary' : 'bg-dark-secondary'}`}
                type="button"
            >
                {props.title}{" "}
                <svg
                    className="w-2.5 h-2.5 ms-3 max-sm:ml-auto"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m1 1 4 4 4-4"
                    />
                </svg>
            </button>
            <div
                className={`absolute min-w-48 w-auto mt-1 z-10 bg-white divide-y divide-gray-100 rounded-lg
            shadow dark:bg-gray-700 dark:divide-gray-600 ${visibility}`}
                key={props.title}
            >
                <ul
                    className="p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200"
                    aria-labelledby="dropdownCheckboxButton"
                >
                    {props.optionsArray.map((value: string) => {
                        return (
                            <li key={value}>
                                <div className="flex items-center">
                                    <input
                                        onChange={(e) => {props.handleChange(e, value); }}
                                        id="checkbox-item-1"
                                        type="checkbox"
                                        defaultChecked={false}
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded
                                        focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700
                                        dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600
                                        dark:border-gray-500"
                                    />
                                    <label
                                        htmlFor="checkbox-item-1"
                                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        {value}
                                    </label>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}
export default Dropdown;
