import React, { FC } from 'react';
import styles from './MobileNav.module.css';
import {NavLink} from "react-router-dom";
import {BookOpen, PlusCircle, UserCircle, UsersThree} from "@phosphor-icons/react";

interface MobileNavProps {

}

const MobileNav: FC<MobileNavProps> = (props) => {

    return (<div className="safari_only sm:hidden">
        <div className="fixed bottom-0 left-0 z-50 w-full h-12 bg-white border-t dark:bg-dark-primary border-dark-secondary">
            <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
                <NavLink to={"/"}
                         className="inline-flex flex-col items-center justify-center px-5 border-dark-secondary
                            border-x  hover:bg-gray-800 group">
                    <BookOpen size={18}
                              className="w-4 h-4 mb-1 text-gray-400 group-hover:text-blue-500"
                              weight="duotone"/>
                    <span className="text-sm text-gray-400 group-hover:text-blue-500">
                            Recipes
                        </span>
                </NavLink>
                <NavLink to={"/recipe/create"}
                         className="inline-flex flex-col items-center justify-center px-5 border-dark-secondary
                             border-0 hover:bg-gray-800 group">
                    <PlusCircle size={18}
                                className="w-4 h-4 mb-1 text-gray-400 group-hover:text-blue-500"
                                weight="duotone"/>
                    <span className="text-sm text-gray-400 group-hover:text-blue-500">
                            Create
                        </span>
                </NavLink>
                <NavLink to={"/share"}
                         className="inline-flex flex-col items-center justify-center px-5 border-dark-secondary
                             border-x hover:bg-gray-800 group">
                    <UsersThree size={18}
                                className="w-4 h-4 mb-1 text-gray-400 group-hover:text-blue-500"
                                weight="duotone"/>
                    <span className="text-sm text-gray-400 group-hover:text-blue-500">
                            Share
                        </span>
                </NavLink>
                <NavLink to={"/profile"}
                         className="inline-flex flex-col items-center justify-center px-5 border-dark-secondary
                             border-0 hover:bg-gray-800 group">
                    <UserCircle size={18}
                                className="w-4 h-4 mb-1 text-gray-400 group-hover:text-blue-500"
                                weight="duotone"/>

                    <span className="text-sm text-gray-400 group-hover:text-blue-500">
                            Settings
                        </span>
                </NavLink>
            </div>
        </div>
    </div>
    );
}

export default MobileNav;
