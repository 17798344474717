import React, {FC, useEffect, useRef, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import UserService from "../../services/UserService";
import {SettingsDto} from "../../models/SettingsDto";
import Button from "../Button/Button";

interface ProfileSettingsProps {}

const ProfileSettings: FC<ProfileSettingsProps> = () => {

    const {getAccessTokenSilently} = useAuth0();
    const token = useRef("");

    const [language, setLanguage] = useState("og");
    const [unitSystem, setUnitSystem] = useState("og");
    const [isLoading, setLoader] = useState(true);
    const [buttonText, setButtonText] = useState("Loading profile...");

    useEffect(() => {
        getAccessTokenSilently().then(result => {
            token.current = result;
            setLoader(true);
            getProfileSettings().then(r => {
                setLanguage(r.language ?? "null");
                setUnitSystem(r.units ?? "null");
                setLoader(false);
            });
        });
    }, [getAccessTokenSilently]);

    const getProfileSettings = async (): Promise<SettingsDto> => {
        const u = new UserService();
        return await u.GetProfile(token.current);
    }

    const handleSubmit = (e: any) => {
        setLoader(true);
        setButtonText("Saving settings...");
        e.preventDefault();
        try {
            const data = new FormData();
            data.append("units", unitSystem);
            data.append("language", language);

            const u = new UserService();
            u.UpdateProfile(token.current, data).then(r => {
                setLoader(false)
                setButtonText("Loading profile...");
            });
        } catch (error) {
            console.error("Error updating profile:", error);
            setLoader(false);
            setButtonText("Loading profile...");
        }
    };

    return (
        <div>
            <div className={"text-left"}>
                <h2 className={"font-nunito text-lg font-bold mb-4"}>My Settings</h2>
            </div>

            <div className={"text-left max-sm:w-full sm:w-1/2"}>
                <form onSubmit={handleSubmit}>
                    <label htmlFor={"language"} className={"font-bold"}>
                        Translate recipes to:
                    </label>

                    <select id="language"
                            name={"language"}
                            value={language}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                        rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full
                                        p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                        dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={(e) => setLanguage(e.target.value)}
                            disabled={isLoading}
                        >
                        <option value="null">Choose your preferred recipe language</option>
                        <option value="og">Keep original</option>
                        <option value="nl">Dutch</option>
                        <option value="en">English</option>
                    </select>

                    <div className={"mt-4 flex"}></div>

                    <label htmlFor={"language"} className={"font-bold"}>
                        Unit system
                    </label>

                    <select id="units"
                            name={"units"}
                            value={unitSystem}
                            onChange={(e) => setUnitSystem(e.target.value)}
                            disabled={isLoading}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                            rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full
                                            p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400
                                            dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                        <option value="null">How should we display units?</option>
                        <option value="og">Keep original</option>
                        <option value="imperial">Imperial (Pound, tsp, oz, etc.)</option>
                        <option value="metric">Metric (Grams, kilos, litres, etc.)</option>
                    </select>

                    <div className={"mt-5"}>
                        <Button isLoading={isLoading} loadingText={buttonText}>
                            Save
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ProfileSettings;
