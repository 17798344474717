import React, {FC} from 'react';
import './RecipeDescription.module.css';
import {Dna, Drop, Fire, Grains} from "@phosphor-icons/react";
import RecipeNutrition from "../RecipeNutrition/RecipeNutrition";
import {Nutrition} from "../../models/Nutrition";

interface RecipeDescriptionProps {
    description: string
    nutrition: Nutrition
}

const RecipeDescription: FC<RecipeDescriptionProps> = (props) => {
    const createMarkup = () => {
        return {__html: props.description};
    }

    let desc = createMarkup();

    return (
        <div className={"block w-full sm:bg-dark-primary rounded-xl"} data-testid="RecipeDescription">
            <RecipeNutrition nutrition={props.nutrition} />
            <div className={"flex flex-column align-items-center align-content-start text-white p-4 text-left " +
                "font-nunito recipeDesc flex-wrap"}
                 dangerouslySetInnerHTML={desc}>
            </div>
        </div>
    );
};

export default RecipeDescription;
