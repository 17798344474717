import LeftMenu from "../components/LeftMenu/LeftMenu";
import React, {useEffect, useRef, useState} from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import styles from "../components/PageTitle/PageTitle.module.css";
import {Bird, BookOpen, Link as LinkIcon, PlusCircle, UserCircle, UsersThree} from "@phosphor-icons/react";
import {useAuth0} from "@auth0/auth0-react";
import RecipeService from "../services/recipeService";
import {Link, NavLink, useParams} from "react-router-dom";
import RecipeTiming from "../components/RecipeTiming/RecipeTiming";
import RecipeIngredients from "../components/RecipeIngredients/RecipeIngredients";
import RecipeDescription from "../components/RecipeDescription/RecipeDescription";
import MobileNav from "../components/MobileNav/MobileNav";

export default function ViewRecipePage() {

    let params = useParams();
    const token = useRef("");
    const {getAccessTokenSilently} = useAuth0();
    const [recipe, setRecipe] = useState({});

    useEffect(() => {
        getAccessTokenSilently().then(result => {
            token.current = result;
            const service = new RecipeService();
            service.getRecipeById(params.id, token.current).then(response => {
                if(response.status === 200) {
                    response.json().then(recipe => {
                        setRecipe(recipe);
                    });
                } else {
                    window.location.href = "/";
                }
            });
        });
    }, []);

    const isValidUrl = urlString=> {
        var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
            '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
        return !!urlPattern.test(urlString);
    }
    const baseUrl = process.env.REACT_APP_S3_URL ?? "";
    const defaultUrl = "https://images.unsplash.com/photo-1546069901-ba9599a7e63c?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8fA%3D%3D";

    return (
        <div>

            <div className={"sm:hidden inline-block mt-2"}>
                <a href="https://cookbird.cloud"
                   className={"text-white text-2xl font-nunito font-black"}
                   target="_blank" rel="noreferrer">
                    COOK<Bird size={25} className={"inline mt-[-5px] ml-[2px]"}/>BIRD
                </a>
                <hr className={"invisible md:visible"}/>
            </div>

            <div className={"main-container"}>
                <div className={"menu-container"}>
                    <LeftMenu/>
                </div>

                <div className={"container-item"}>

                    <img src={recipe.images?.length > 0
                        ? baseUrl + recipe.images[0].name
                        : defaultUrl}
                         alt={""}
                         className={"sm:hidden"}
                    />

                    <RecipeTiming id={recipe.id} timing={recipe.times} token={token.current}/>

                    <PageTitle
                        title={recipe.title}
                        icon={<BookOpen className={styles.TitleIcon} size={30} weight={"bold"}/>}
                        controls={<></>}
                    />

                    <div className={"break"}></div>

                    <div className={"w-full md:flex md:justify-between md:gap-4"}>
                        <RecipeIngredients servings={recipe.servings} ingredients={recipe.ingredients}/>
                        <RecipeDescription nutrition={recipe.nutrition} description={recipe.recipe}/>
                    </div>

                    {isValidUrl(recipe.source) ? <span className={"ml-[4px] -mb-28 text-white text-xs font-nunito"}>
                        Source: <Link to={recipe.source}> {recipe.source} <LinkIcon
                        className={"inline ml-[2px]"}/></Link>
                    </span> : ""}
                </div>
            </div>

            <MobileNav/>
        </div>
    );
}