import {SettingsDto} from "../models/SettingsDto";

export default class UserService {

    base: string = process.env.REACT_APP_API_URL ?? "";

    async GetProfile(token: string): Promise<SettingsDto>
    {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        };
        let response = await fetch(this.base + "Settings", requestOptions);
        return response.json();
    }
    async UpdateProfile(token: string, form: FormData)
    {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: form
        };
        return await fetch(this.base + "Settings", requestOptions);
    }
}