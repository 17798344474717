import React, {FC, useState} from 'react';
import styles from './PageTitle.module.css';

interface PageTitleProps {
    title: string,
    icon: any,
    controls: any
}

const PageTitle: FC<PageTitleProps> = (props) => {

    return (
        <div className={styles.PageTitle} data-testid="PageTitle">

            <div className={styles.Title}>
                {props.icon} {props.title}
            </div>

            {props.controls}
        </div>
    );
}

export default PageTitle;
