import LeftMenu from "../components/LeftMenu/LeftMenu";
import React, {useEffect, useRef} from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import styles from "../components/PageTitle/PageTitle.module.css";
import {Bird, UserCircle} from "@phosphor-icons/react";
import {useAuth0} from "@auth0/auth0-react";
import {useParams} from "react-router-dom";
import ProfileSettings from "../components/ProfileSettings/ProfileSettings";
import MobileNav from "../components/MobileNav/MobileNav";

export default function ProfilePage() {

    let params = useParams();
    const token = useRef("");
    const {getAccessTokenSilently, user} = useAuth0();

    useEffect(() => {
        getAccessTokenSilently().then(result => {
            token.current = result;
        });
    }, []);

    return (
        <div>
            <div className={"sm:hidden inline-block mt-2"}>
                <a href="https://cookbird.cloud"
                   className={"text-white text-2xl font-nunito font-black"}
                   target="_blank" rel="noreferrer">
                    COOK<Bird size={25} className={"inline mt-[-5px] ml-[2px]"}/>BIRD
                </a>
                <hr className={"invisible md:visible"}/>
            </div>
            <div className={"main-container"}>
                <div className={"menu-container"}>
                    <LeftMenu/>
                </div>

                <div className={"container-item"}>
                    <PageTitle
                        title={"Your Profile"}
                        icon={<UserCircle className={styles.TitleIcon} size={30} weight={"bold"}/>}
                        controls={<></>}
                    />
                    <div className={"break"}></div>

                    <div className={"flex grow-[1]"}>
                        <div className="w-full mb-[58px]">
                            <div
                                className={"max-sm:mx-4 bg-dark-primary flex flex-col flex-wrap align-items-start text-white" +
                                    " align-items-start shadow-md " +
                                    "rounded-2xl px-8 pt-6 pb-8 mb-4"}>
                                <div className={"text-left"}>
                                    <h2 className={"font-nunito text-lg font-bold mb-4"}>Your Account</h2>
                                </div>

                                <div className={"text-left"}>
                                    <label htmlFor={"username"} className={"font-bold"}>
                                        Username
                                    </label>
                                    <input className="shadow mt-2 appearance-none border rounded flex py-2
                                       px-3 text-gray-600 leading-tight focus:outline-none focus:shadow-outline w-1/3
                                       disabled:bg-gray-300 disabled:text-gray-600 max-sm:w-full"
                                           type="text"
                                           name={"username"}
                                           value={user?.name}
                                           disabled
                                    />
                                </div>

                                <div className={"text-left mt-4"}>
                                    <label htmlFor={"email"} className={"font-bold"}>
                                        Email
                                    </label>
                                    <input className="shadow mt-2 appearance-none border rounded flex py-2
                                       px-3 text-gray-600 leading-tight focus:outline-none focus:shadow-outline w-1/3
                                       disabled:bg-gray-300 disabled:text-gray-600 max-sm:w-full"
                                           type="text"
                                           name={"username"}
                                           value={user?.email}
                                           disabled
                                    />
                                </div>

                                <hr className="h-px mt-8 mb-6 bg-gray-200 border-0 dark:bg-gray-700"/>

                                <ProfileSettings/>

                                <hr className="h-px mt-8 mb-6 bg-gray-200 border-0 dark:bg-gray-700"/>


                                <div className={"text-left"}>
                                    <h2 className={"font-nunito text-lg font-bold mb-4"}>Account status</h2>
                                </div>

                                <div className={"text-left"}>

                                    <label htmlFor={"upgrade"} className={"font-bold"}>
                                        Account: Regular (Free)
                                    </label>

                                    <button
                                        className="flex-inline sm:ml-2 bg-gray-300 hover:bg-blue-700 text-gray-700
                                    font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                        type="submit"
                                        name={"upgrade"}
                                    > Upgrade to Premium
                                    </button>

                                    <p className={"w-3/4 mt-4"}>Upgrading your account will give you additional features
                                        such as unlocking unlimited
                                        recipes. If you like this service, consider upgrading and supporting this
                                        platform! </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MobileNav/>
        </div>
        );
    }