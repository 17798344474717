import React, { FC } from 'react';
import {SlidersHorizontal} from "@phosphor-icons/react";

interface ActivateFilterButtonProps {
    toggleFilter: any
}

const ActivateFilterButton: FC<ActivateFilterButtonProps> = (props) => (
  <div className={"flex items-center sm:hidden"}
       data-testid="ActivateFilterButton"
       onClick={() => props.toggleFilter()}>
    Filter <SlidersHorizontal className="ml-3" size={28} />
  </div>
);

export default ActivateFilterButton;
