import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./routes/error-page";
import RecipesPage from "./routes/recipes-page";
import CreateRecipePage from "./routes/create-recipe-page";
import { Auth0Provider } from '@auth0/auth0-react';
import {Provider} from "react-redux";
import store from "./store";
import ViewRecipePage from "./routes/view-recipe";
import ProfilePage from "./routes/profile";
import EditRecipePage from "./routes/edit-recipe-page";
import SharePage from "./routes/share";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        element: <App/>,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <RecipesPage />,
            },
            {
                path: "/recipe/create",
                element: <CreateRecipePage />,
            },
            {
                path: "/recipe/edit/:id",
                element: <EditRecipePage />,
            },
            {
                path: "/recipe/:id",
                element: <ViewRecipePage />,
            },
            {
                path: "/mealplan",
                element: <CreateRecipePage />,
            },
            {
                path: "/shopping",
                element: <CreateRecipePage />,
            },
            {
                path: "/share",
                element: <SharePage />,
            },
            {
                path: "/find",
                element: <CreateRecipePage />,
            },
            {
                path: "/profile",
                element: <ProfilePage />,
            },
        ]
    },
]);

root.render(
    <Auth0Provider
        domain="dev-bdj1m3kl6sw7slly.us.auth0.com"
        clientId="W5tMoWZF3WC47HZ42BLNPyYbKxrMwEiE"
        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: "https://dev-bdj1m3kl6sw7slly.us.auth0.com/api/v2/"
        }}
        cacheLocation= 'localstorage'
    >
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
