import React, {FC, useEffect, useState} from 'react';
import RecipeService from "../../services/recipeService";
import './SearchByUrl.module.css';
import {useDispatch} from "react-redux";
import {setRecipe} from "../../features/recipe/recipeSlice";
import {useAuth0} from "@auth0/auth0-react";
import {setChooseState} from "../../features/recipe/chooseSlice";
import {SubmitHandler, useForm} from "react-hook-form";
import Button from "../Button/Button";

interface SearchByUrlProps {}
interface IFormValues {
    url: string,
    limit: string
}

const SearchByUrl: FC<SearchByUrlProps> = () => {

    const [state, setState] = useState(
        {
            url: "",
        }
    );

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<IFormValues>();

    const [isLoading, setLoading] = useState(false);
    let token = "";
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        console.log(errors);
        getAccessTokenSilently().then(result => {
            token = result;
        })
    });

    const dispatch = useDispatch()

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setState((prevProps) => ({
            ...prevProps,
            [name]: value
        }));
    };

    const onSubmit: SubmitHandler<IFormValues> = (data) => {
        let service = new RecipeService();
        setLoading(true);
        service.getRecipe(data.url, token)
        .then(res => {
            setLoading(false);
            if(!res.ok) {
                res.json()
                    .then(resp => {
                        Object.entries(resp.errors).map(([key, value]) => {
                            const fieldName = key as keyof {
                                url: string;
                                limit: string
                            }
                            let errorMessage = value as Array<string>;
                            setError(fieldName, {
                                type: "custom",
                                message: errorMessage[0]
                            });
                        });
                    })
            } else {
                res.json()
                    .then(recipe => {
                        dispatch(setRecipe(recipe));
                        dispatch(setChooseState("edit"))
                    });
            }
        });
    }

    return (<div data-testid="SearchByUrl" className={"flex grow-[1]"}>
            <div className="w-full">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="m-4 bg-dark-primary shadow-md rounded-2xl px-8 pt-6 pb-8 mb-4"
                >
                    {errors.limit ? <span className={"mb-5 justify-content-start flex text-red-500 font-bold"}>
                    {errors.limit.message}
                </span> : ""}
                    <div className={"justify-content-start flex"}>
                        <label className={"text-sm font-bold mb-2 text-white"}
                               htmlFor="url">
                            Copy recipe to your recipe book using a URL.
                        </label>
                        <span
                            className={"text-sm" + (errors.url ? "text-red-500" : "text-white ") + " font-bold mb-2"}>
                        </span>
                    </div>
                    <div className="mb-2 align-items-start flex">
                        <input className={"shadow appearance-none border " + (errors.url ? "border-red-500" : "")
                            + " rounded w-full py-2 px-3 text-gray-600 leading-tight focus:outline-none" +
                            " focus:shadow-outline"}
                               id="url"
                               {...register("url", {required: "URL is required"})}
                               type="text"
                               name="url"
                               value={state.url}
                               onChange={handleInputChange}
                               autoComplete={"off"}
                               placeholder="https://example.com/recipe/tandoori"/>
                    </div>
                    <div className={"justify-content-start flex"}>
                        <span
                            className={"text-sm text-red-500 font-bold mb-2"}>
                            {errors.url && <p>{errors.url.message}</p>}
                        </span>
                    </div>

                    <div className={"flex justify-content-end"}>
                        <Button isLoading={isLoading}>
                            Fetch Recipe
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );

}

export default SearchByUrl;
