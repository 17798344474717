import React, { FC } from 'react';
import styles from './RecipeGrid.module.css';
import {Link} from "react-router-dom";

interface RecipeGridProps {
    id: number
    url: string,
    title: string
}

const RecipeGrid: FC<RecipeGridProps> = (props) => (
    <Link to={"/recipe/" + props.id} className={styles.RecipeGrid} data-testid="RecipeGrid" key={props.id}>
        <img src={`${props.url}`} alt={""}/>
        <span>{props.title}</span>
    </Link>
);

export default RecipeGrid;
