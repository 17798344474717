import React, {FC, useState} from 'react';
import {Ingredient} from "../../models/Ingredient";
import './RecipeIngredients.module.css';
import {ForkKnife} from "@phosphor-icons/react";

interface RecipeIngredientsProps {
    ingredients: Ingredient[]
    servings: number
}

const RecipeIngredients: FC<RecipeIngredientsProps> = (props) => {

    const [toggledIngredients, setToggledIngredients] = useState<number[]>([]);
    const toggleIngredient = (ingredientId: number) => {
        if (toggledIngredients.includes(ingredientId)) {
            setToggledIngredients(toggledIngredients.filter(id => id !== ingredientId));
        } else {
            setToggledIngredients([...toggledIngredients, ingredientId]);
        }
    };


    return (
        <div className={"flex-[21rem] order-1"}>
            <div className={"block text flex-wrap flex-row text-white"}
                 data-testid="RecipeIngredients">
                <span className={"block text-left sm:rounded-t-xl sm:h-10 sm:pt-2 sm:pb-2 pl-4 font-nunito " +
                    "text-lg font-bold sm:bg-dark-primary"}>
                    {props.servings ? <div className={"inline-block"}>
                        <ForkKnife weight={"bold"} color={"#fff"} className={"inline-block mt-[-5px]"} />
                        <span className={"font-bold ml-1 text-m"}>{props.servings} -&nbsp;</span>
                    </div> : ""}
                     Ingredients
                </span>
                <ul className={"block font-nunito max-h-[400px] ingredients overflow-y-auto"}>
                    {props.ingredients?.map((ingredient) => (
                        <li
                            key={ingredient.id}
                            className={"max-sm:pl-2.5 sm:pl-3 flex text-left py-1 sm:odd:bg-dark-primary " +
                            "sm:odd:text-gray-400 sm:even:bg-dark-secondary sm:even:text-gray-300 " +
                            "max-sm:border-dashed max-sm:border-b max-sm:border-white"}
                            onClick={() => toggleIngredient(ingredient.id)}
                        >
                            <span
                                className={`p-2 select-none ${toggledIngredients.includes(ingredient.id) ? 'line-through' : ''}`}
                                key={ingredient.id}
                            >
                                {ingredient.name}
                            </span>
                        </li>
                    ))}
                </ul>
                <div className={"sm:block sm:text-left sm:rounded-b-xl sm:h-10 pl-2 sm:font-nunito " +
                    "sm:text-lg sm:font-bold sm:bg-dark-primary"}>
                </div>
            </div>
        </div>

    );
}
export default RecipeIngredients;
