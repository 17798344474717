import React, {FC, useEffect, useRef, useState} from 'react';
import {Recipe} from "../../models/Recipe";
import Input from "../Forms/Input/Forms/Input";
import {Dna, Drop, Fire, Grains, Plus, Trash, XCircle} from "@phosphor-icons/react";
import {Ingredient} from "../../models/Ingredient";
import {useAuth0} from "@auth0/auth0-react";
import RecipeService from "../../services/recipeService";
import ReactQuill from "react-quill";
import './theme.css';


interface RecipeEditFormProps {
    recipe: Recipe
}

const RecipeEditForm: FC<RecipeEditFormProps> = (props) => {

    const baseImageUrl: string = process.env.REACT_APP_S3_URL ?? "";

    const [recipe,setRecipe]
        = useState(props.recipe ?? {} as Recipe);

    const [ingredients, setIngredients]
        = useState(recipe.ingredients ?? [] as Ingredient[]);

    const {getAccessTokenSilently}
        = useAuth0();

    const token = useRef("");

    const [files, setFiles] = useState<File[]>([]);
    const [recipeText, setRecipeText] = useState('');

    async function fetchImages(r: Recipe) {
        const imageFiles = [];
        for (const image of r.images) {
            try {
                const response = await fetch(`${baseImageUrl}${image.name}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch image');
                }
                const blob = await response.blob();
                const file = new File([blob], image.name);
                imageFiles.push(file);
            } catch (error) {
                console.error('Error fetching image:', error);
            }
        }
        return imageFiles;
    }

    useEffect(() => {
        if (recipe.images != null) {
            fetchImages(recipe)
                .then((imageFiles) => {
                    setFiles([...files, ...imageFiles]);
                })
                .catch((error) => {
                    console.error('Error fetching images:', error);
                });
        }
    }, []);

    const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const selectedFiles = Array.from(e.target.files);
            setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
        }
    }

    const deleteFile = (fileToRemove: File) => {
        const newFiles = files.filter(file => file !== fileToRemove);
        setFiles(newFiles);
    }

    const packFiles = (files: any) => {
        const data = new FormData();
        [...files].forEach((file: any, i: any) => {
            data.append(`file-${i}`, file, file.name)
        })
        return data
    }

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setRecipe((prevProps) => ({
            ...prevProps,
            [name]: value
        }));
    };

    const onIngredientsChange = (event: any, id: number) => {
        const updatedIngredients = ingredients?.map((ingredient) => {
            if (ingredient.id === id) {
                return {
                    ...ingredient,
                    name: event.target.value,
                } as Ingredient;
            }
            return ingredient;
        });
        setIngredients(updatedIngredients);
    };

    const addEmptyIngredient = () => {
        const newIngredient = {
            id: ingredients.length + 1, // Assuming IDs are incrementing integers
            name: '', // You can set any default value for name
        };
        setIngredients([...ingredients, newIngredient]);
    };

    const removeIngredient = (event: any, id: number) => {
        const updatedIngredients = ingredients?.filter(ingredient => ingredient.id !== id);
        setIngredients(updatedIngredients);
    };

    const putRecipe = (id: number, r: FormData) => {
        const service = new RecipeService();
        service.putRecipe(id, r, token.current).then((res) => {
            if (res.ok) {
                window.location.href = `/recipe/${id}`;
            }
        });
    }
    const handleSubmit = (event:any) => {
        event.preventDefault();
        let r = {} as Recipe;

        r.id = recipe.id ?? "";
        r.userId = recipe.userId ?? "";
        r.title = event.target.title?.value;
        r.category = event.target.category?.value;
        r.course = event.target.course?.value;

        r.recipe = recipeText.length > 0 ? recipeText : recipe.recipe;
        r.servings = event.target.servings?.value;

        r.nutrition = {
            id: recipe.nutrition?.id ?? 0,
            fat: event.target.fat?.value,
            protein: event.target.protein?.value,
            carbs: event.target.carbs?.value,
            calories: event.target.calories?.value,
        };

        r.times = {
            id: recipe.times?.id ?? 0,
            prepTime: event.target.prepTime?.value ?? recipe.times?.prepTime ?? "",
            cookTime: event.target.cookTime?.value ?? recipe.times?.cookTime ?? "",
            totalTime: event.target.totalTime?.value ?? recipe.times?.totalTime ?? "",
        };

        r.ingredients = ingredients;
        r.source = recipe.source.length > 0 ? recipe.source : "self";

        const formData = new FormData();
        formData.append('Recipe', JSON.stringify(r));
        const uploadedFiles = packFiles(files);

        uploadedFiles.forEach(file => {
            formData.append("Images", file);
        });
        getAccessTokenSilently().then(result => {
            token.current = result;
            putRecipe(r.id, formData);
        });
    };

    return (
        <div className={"flex grow-[1]"}>
            <div className="w-full">
                <form onSubmit={handleSubmit}
                      className="bg-dark-primary shadow-md rounded-2xl px-8 pt-6 pb-8 mb-4">

                    <div className={"justify-content-start flex"}>
                        <label className="text-white text-sm font-bold mb-2"
                               htmlFor="title">
                            Title of your recipe
                        </label>
                    </div>

                    <div className="mb-4 align-items-start">
                        <Input name={"title"} placeholder={"Tandoori Chicken"} value={recipe.title}/>
                    </div>

                    <div className={"flex row-auto gap-8"}>

                        <div className={"w-full"}>
                            <div className={"justify-content-start flex"}>
                                <label className="text-white text-sm font-bold mb-2"
                                       htmlFor="category">
                                    Category that the recipe belongs to
                                </label>
                            </div>

                            <div className="mb-4">
                                <Input name={"category"} placeholder={"Pasta"} value={recipe.category}/>
                            </div>
                        </div>

                        <div className={"w-full"}>
                            <div className={"justify-content-start flex"}>
                                <label className="text-white text-sm font-bold mb-2"
                                       htmlFor="course">
                                    Course
                                </label>
                            </div>

                            <div className="mb-4">
                                <Input name={"course"} placeholder={"Dinner"} value={recipe.course}/>
                            </div>

                        </div>

                        <div className={""}>
                            <div className={"justify-content-start flex"}>
                                <label className="text-white text-sm font-bold mb-2"
                                       htmlFor="servings">
                                    Servings
                                </label>
                            </div>
                            <div className="mb-4 align-items-start flex">
                                <Input name={"servings"} placeholder={"3"} value={recipe.servings}
                                       icon={<Fire className={"w-4 h-4 text-gray-500"}/>}/>
                            </div>
                        </div>
                    </div>

                    <div className={"flex row-auto gap-8"}>

                        <div className={"w-full"}>
                            <div className={"justify-content-start flex"}>
                                <label className="text-white text-sm font-bold mb-2"
                                       htmlFor="Recipe">
                                    Recipe
                                </label>
                            </div>

                            <div className="mb-4">
                                <ReactQuill
                                    className={"bg-white rounded-xl"}
                                    onChange={setRecipeText}
                                    theme="snow"
                                    defaultValue={recipe.recipe}
                                    modules={{toolbar: true}}
                                    placeholder="Recipe to follow"
                                />
                            </div>
                        </div>

                        <div className={"w-full"}>

                            <div className={"justify-content-start flex"}>
                                <label className="text-white text-sm font-bold mb-2">
                                    Ingredients
                                    <button type="button"
                                            onClick={addEmptyIngredient}
                                            className="w-[2rem] h-[2rem] flex-shrink-0 ml-4
                                            inline-flex justify-center items-center
                                            text-sm rounded-md
                                            border border-transparent bg-gray-300 text-gray-800 hover:text-gray-200 hover:bg-gray-500
                                            disabled:opacity-50 disabled:pointer-events-none">
                                        <Plus className="flex-shrink-0 size-4" size={32}/>
                                    </button>
                                </label>
                            </div>

                            <div className="mb-4">
                                <ul>
                                    {ingredients.length == 0 ? <span className={"text-white flex font-nunito text-sm"}>
                                        Make sure to add ingredients!
                                    </span> : ""}
                                    {ingredients?.map((ingredient) => (
                                        <div className="flex">
                                            <input type="text"
                                                   className="mt-2 py-2 px-4 block w-full border-gray-200 shadow-sm
                                                   rounded-s-lg text-sm focus:z-10 focus:border-blue-500
                                                   focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                                                   key={ingredient.id}
                                                   value={ingredient.name}
                                                   onChange={(event) => onIngredientsChange(event, ingredient.id)}
                                            />
                                            <button type="button"
                                                    onClick={(event) => removeIngredient(event, ingredient.id)}
                                                    className="mt-2 w-[2.4rem] h-[2.4rem] flex-shrink-0 inline-flex
                                                    justify-center items-center gap-x-2 text-sm font-semibold
                                                    rounded-e-md border border-transparent bg-gray-300 text-gray-800
                                                    hover:text-gray-200 hover:bg-gray-500 disabled:opacity-50
                                                    disabled:pointer-events-none dark:focus:outline-none
                                                     dark:focus:ring-1 dark:focus:ring-gray-600">
                                                <XCircle className="flex-shrink-0 size-4" size={32}/>
                                            </button>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={"justify-content-start flex"}>
                        <label className="text-white text-sm font-bold mb-2">
                            Nutrition
                        </label>
                    </div>

                    <div className="mb-4">
                        <Input name={"carbs"} placeholder={"Carbs"} value={recipe?.nutrition?.carbs ?? ""}
                               icon={<Grains className={"w-4 h-4 text-gray-500"}/>}/>
                    </div>

                    <div className="mb-4">
                        <Input name={"fat"} placeholder={"Fat"} value={recipe?.nutrition?.fat}
                               icon={<Drop className={"w-4 h-4 text-gray-500"}/>}/>
                    </div>

                    <div className="mb-4">
                        <Input name={"protein"} placeholder={"Protein"} value={recipe?.nutrition?.protein}
                               icon={<Dna className={"w-4 h-4 text-gray-500"}/>}
                        />
                    </div>

                    <div className="mb-4">
                        <Input name={"calories"} placeholder={"Calories"} value={recipe?.nutrition?.calories}
                               icon={<Fire className={"w-4 h-4 text-gray-500"}/>}/>
                    </div>

                    <div className="mb-4 flex items-center justify-start cursor-pointer flex-column w-full gap-4">
                        {files.map((file, key) => (
                            <div className={"flex gap-4"} onClick={() => deleteFile(file)}>
                                    <span className={"absolute m-[5px] text-white"}>
                                        <Trash size={23}/>
                                    </span>
                                <img
                                    key={key}
                                    className={"max-h-[250px] object-fit-cover object-center rounded border-white border-2"}
                                    src={URL.createObjectURL(file)}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="mb-4 flex items-center justify-center w-full">
                        <label htmlFor="image" className="flex flex-col items-center justify-center
                        w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer
                        bg-gray-50">
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg className="w-8 h-8 mb-4 text-gray-500"
                                     aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg"
                                     fill="none"
                                     viewBox="0 0 20 16">
                                    <path stroke="currentColor" strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5
                                           0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0
                                           0L8 8m2-2 2 2"/>
                                </svg>
                                <p className="mb-2 text-sm text-gray-500">
                                    <span className="font-semibold">
                                        Click to upload
                                    </span> or drag and drop
                                </p>
                                <p className="text-xs text-gray-500">
                                    SVG, PNG, JPG or GIF (MAX. 800x400px)
                                </p>
                            </div>
                            <input id="image"
                                   type="file"
                                   name="image"
                                   className="hidden"
                                   multiple
                                   onChange={onFilesChange}
                            />
                        </label>
                    </div>

                    <div className={"flex justify-content-end"}>
                        <button
                            className="bg-gray-300 hover:bg-blue-700 text-gray-700 font-bold py-2 px-4
                            rounded focus:outline-none focus:shadow-outline"
                            type="submit"> Save and continue
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default RecipeEditForm;
