import React, {FC, useEffect, useRef, useState} from 'react';
import styles from './OpenInvites.module.css';
import Input from "../Forms/Input/Forms/Input";
import ShareService from "../../services/ShareService";
import {useAuth0} from "@auth0/auth0-react";
import {AccessDto} from "../../models/AccessDto";
import {CheckFat, Share} from "@phosphor-icons/react";

interface OpenInvitesProps {}

const OpenInvites: FC<OpenInvitesProps> = () => {

    const {getAccessTokenSilently} = useAuth0();
    const token = useRef("");

    const [invites, setInvites] = useState<AccessDto[]>([]);

    useEffect(() => {
        getAccessTokenSilently().then(result => {
            token.current = result;
            getInvites();
        });
    }, [getAccessTokenSilently]);

    const accept = (access: AccessDto) => {
        const share = new ShareService();
        share.Accept(token.current, access.userId).then(r => {
            console.log(r);
            getInvites();
        });
    }
    const deny = (access: AccessDto) => {
        const share = new ShareService();
        share.Deny(token.current, access.userId).then(r => {
            console.log(r);
            getInvites();
        });
    }
    const getInvites = () => {
        const share = new ShareService();
        share
            .GetMyInvites(token.current)
            .then(res => {
                res.map(access => {
                    if(!access.accepted)
                        setInvites(prevItems => [...prevItems, access]);
                })
            });

    };

    return (
        <div>
            <div className={"text-left"}>
                <h2 className={"font-nunito text-lg font-bold mb-4"}>Open Invites ({invites.length ?? 0})</h2>
                {invites?.map(invite => {
                    return (
                        <div key={invite.userIdAllowed} className={"inline-flex gap-2"}>
                        <button type="button"
                                onClick={() => accept(invite)}
                                className="text-blue-700 border border-blue-700 hover:bg-blue-700 hover:text-white
                                 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full
                                 text-sm p-2.5 text-center inline-flex items-center dark:border-blue-500
                                 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800
                                 dark:hover:bg-blue-500"
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="currentColor" viewBox="0 0 18 18">
                                <path
                                    d="M3 7H1a1 1 0 0 0-1 1v8a2 2 0 0 0 4 0V8a1 1 0 0 0-1-1Zm12.954 0H12l1.558-4.5a1.778 1.778 0 0 0-3.331-1.06A24.859 24.859 0 0 1 6 6.8v9.586h.114C8.223 16.969 11.015 18 13.6 18c1.4 0 1.592-.526 1.88-1.317l2.354-7A2 2 0 0 0 15.954 7Z"/>
                            </svg>
                        </button>
                        <button type="button"
                                onClick={() => deny(invite)}
                                className="text-blue-700 border border-red-700 hover:bg-red-700 hover:text-white
                                focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm
                                 p-2.5 text-center inline-flex items-center dark:border-red-500 dark:text-red-500
                                 dark:hover:text-white dark:focus:ring-red-800 dark:hover:bg-red-500"
                        >
                            <svg className="w-3 h-3 rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="currentColor" viewBox="0 0 18 18">
                                <path
                                    d="M3 7H1a1 1 0 0 0-1 1v8a2 2 0 0 0 4 0V8a1 1 0 0 0-1-1Zm12.954 0H12l1.558-4.5a1.778 1.778 0 0 0-3.331-1.06A24.859 24.859 0 0 1 6 6.8v9.586h.114C8.223 16.969 11.015 18 13.6 18c1.4 0 1.592-.526 1.88-1.317l2.354-7A2 2 0 0 0 15.954 7Z"/>
                            </svg>
                        </button>
                        <div className={"flex self-center"}>
                            invited by&nbsp;<b>{invite.userId}</b>
                        </div>
                    </div>)
                }) ?? "Ask a friend to invite you to start sharing recipes!"}
            </div>
        </div>
    );
}

export default OpenInvites;
