import LeftMenu from "../components/LeftMenu/LeftMenu";
import Recipes from "../components/Recipes/Recipes";
import React, {useEffect, useRef, useState} from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import styles from "../components/PageTitle/PageTitle.module.css";
import {Bird, BookOpen} from "@phosphor-icons/react";
import {useAuth0} from "@auth0/auth0-react";
import RecipeService from "../services/recipeService";
import {FilterProvider} from "../components/FilterContext/FilterContext";
import CategoryFilterComponent from "../components/Filters/CategoryFilterComponent/CategoryFilterComponent";
import CourseFilterComponent from "../components/Filters/CourseFilterComponent/CourseFilterComponent";
import TitleFilterComponent from "../components/Filters/TitleFilterComponent/TitleFilterComponent";
import TimeFilterComponent from "../components/Filters/TimeFilterComponent/TimeFilterComponent";
import ShareService from "../services/ShareService";
import SharedRecipesBooks from "../components/SharedRecipesBooks/SharedRecipesBooks";
import ActivateFilterButton from "../components/ActivateFilterButton/ActivateFilterButton";
import {filter} from "rxjs";
import MobileNav from "../components/MobileNav/MobileNav";

export default function RecipesPage() {

    const token = useRef("");
    const {getAccessTokenSilently} = useAuth0();
    const [allRecipes, setRecipes] = useState([]);
    const [ogRecipes, setOgRecipes] = useState([]);
    const [allowedBooks, setAllowedBooks] = useState([]);
    const [filterToggleState, setFilterToggleState] = useState(null);

    const getRecipes = () => {
        const service = new RecipeService();
        service.getRecipes(token.current).then(recipeArray => {
            setRecipes(recipeArray);
            setOgRecipes(recipeArray);
        });
    }

    useEffect(() => {
        if(filterToggleState == null && window.innerWidth >= 640) {
            setFilterToggleState(true);
        }
        getAccessTokenSilently().then(result => {
            token.current = result;
            getRecipes();
            getSharedRecipes();
        });
    }, [getAccessTokenSilently]);

    const getCourseOptions = () => {
        const courses = [];
        ogRecipes.forEach(recipe => {
            let course = recipe.course.toLowerCase();
            if(!courses.includes(course)) {
                courses.push(course)
            }
        });
        return courses;
    }

    const getTimeOptions = () => {
        const timingArray = [
            "< 20 minutes",
            "< 30 minutes",
            "< 1 hour",
            "< 2 hours"
        ];

        return timingArray;
    }

    const getCategoryOptions = () => {
        const categories = [];
        ogRecipes.forEach(recipe => {
            let category = recipe.category.toLowerCase();
            if(!categories.includes(category)) {
                categories.push(category);
            }
        });
        return categories;
    }

    const getSharedRecipes = () => {
        const share = new ShareService();
        const allowed = share.GetAllowedBooks(token.current);
        allowed.then(allowedBooks => {
            setAllowedBooks(allowedBooks);
            allowedBooks.map(allowedBook => {
                allowedBook.recipes.map(sharedRecipe => {
                    if(!allRecipes.includes(sharedRecipe.title)) {
                        setRecipes(prev => [...prev, sharedRecipe]);
                    }
                })
            })
        })
    }

    const toggleFiltersCode = () => {
        if(filterToggleState) {
            setFilterToggleState(false);
        } else {
            setFilterToggleState(true);
        }
    }


    return (
        <div>
            <div className={"sm:hidden inline-block mt-2"}>
                <a href="https://cookbird.cloud"
                   className={"text-white text-2xl font-nunito font-black"}
                   target="_blank" rel="noreferrer">
                    COOK<Bird size={25} className={"inline mt-[-5px] ml-[2px]"}/>BIRD
                </a>
                <hr className={"invisible md:visible"}/>
            </div>

            <div className={"main-container"}>

                <div className={"menu-container"}>
                    <LeftMenu/>
                </div>

                <div className={"container-item"}>

                    <PageTitle
                        title={"My Recipes"}
                        icon={<BookOpen className={styles.TitleIcon} size={30} weight={"bold"}/>}
                        controls={<ActivateFilterButton toggleFilter={toggleFiltersCode}/>}
                    />

                    <div className={"break"}></div>

                    <FilterProvider>
                        <div>
                            {filterToggleState ? <div className={"flex flex-wrap gap-2 sm:mb-6 max-sm:p-4"}>
                                <CategoryFilterComponent options={getCategoryOptions()}/>
                                <CourseFilterComponent options={getCourseOptions()}/>
                                <TimeFilterComponent options={getTimeOptions()}/>
                                <TitleFilterComponent/>
                            </div> : ""}

                            <div className={"max-sm:flex max-sm:flex-wrap max-sm:px-4 max-sm:mb-[58px]"}>
                                <SharedRecipesBooks shared={allowedBooks}/>
                                <Recipes recipes={allRecipes}/>
                            </div>
                        </div>
                    </FilterProvider>
                </div>

                <MobileNav/>
            </div>
        </div>
    );
}