import React, {FC, useState} from 'react';
import {CookingPot, Knife, NotePencil, Timer, Trash} from "@phosphor-icons/react";
import {Times} from "../../models/Times";
import RecipeService from "../../services/recipeService";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import {Duration} from "luxon";
import IsoTimeFormatComponent from "../IsoTimeFormatComponent/IsoTimeFormatComponent";

interface RecipeTimingProps {
    timing: Times,
    id: number,
    token: any
}

const RecipeTiming: FC<RecipeTimingProps> = (props) => {

    const handleDeleteClick = () => {
        const service = new RecipeService();
        service.deleteRecipe(props.id, props.token).then(r => window.location.href = "/");
    };

    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    const openModal = () => setOpen(true);

    const totalTime = Duration.fromISO(props.timing?.totalTime);
    const prepTime = Duration.fromISO(props.timing?.prepTime);
    const cookTime = Duration.fromISO(props.timing?.cookTime);

    return (
        <div className={"sm:bg-dark-primary flex text-white sm:min-h-[50px] w-full rounded-xl"} data-testid="RecipeTiming">
            <div className={"gap-4 sm:items-center flex text align-items-center pl-[16px]"}>
                <div className={"flex"}>
                    <div className={"pr-1 mt-0.5"}>
                        <Timer size={20}/>
                    </div>
                    Total: <IsoTimeFormatComponent time={totalTime} />
                </div>
                <div className={"flex"}>
                    <div className={"pr-1 mt-0.5"}>
                        <Knife size={20}/>
                    </div>
                    Prep: <IsoTimeFormatComponent time={prepTime} />
                </div>
                <div className={"flex"}>
                    <div className={"pr-1 mt-0.5"}>
                        <CookingPot size={20}/>
                    </div>
                    Cook: <IsoTimeFormatComponent time={cookTime} />
                </div>
            </div>
            <div className={"flex align-items-center ml-auto pr-6 sm:items-center"}>

                <div className={"pr-3 mt-0.5 hover:underline font-nunito cursor-pointer gap-1 select-none inline-flex"}
                     onClick={() => {window.location.href = `/recipe/edit/${props.id}`}}>
                    <NotePencil className={"mt-[2px]"} size={20}/> Edit
                </div>

                <div className={"pr-1 mt-0.5 hover:underline font-nunito cursor-pointer gap-1 select-none inline-flex"}
                     onClick={openModal}>
                    <Trash className={"mt-[2px]"} size={20}/> Delete
                </div>

                <div className={"flex"}>
                    <Popup
                        open={open}
                        onClose={closeModal}
                        modal
                        lockScroll={true}
                    >
                      <div className={"p-4"}>
                          <span className={"block"}>
                              Are you sure you want to delete this recipe?
                          </span>
                          <div className={"mt-4"}>
                              <button className={"bg-green-400 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mr-4 rounded inline-flex items-center"}
                                      onClick={closeModal}>
                                  Cancel
                              </button>

                              <button className={"bg-red-400 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"}
                                      onClick={handleDeleteClick}>
                                  Confirm deletion
                              </button>
                          </div>
                      </div>
                    </Popup>
                </div>
            </div>
        </div>);
}

export default RecipeTiming;
