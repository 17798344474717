import LeftMenu from "../components/LeftMenu/LeftMenu";
import React, {useEffect, useRef, useState} from "react";
import PageTitle from "../components/PageTitle/PageTitle";
import styles from "../components/PageTitle/PageTitle.module.css";
import {PlusCircle} from "@phosphor-icons/react";
import RecipeEditForm from "../components/RecipeEditForm/RecipeEditForm";
import {useParams} from "react-router-dom";
import RecipeService from "../services/recipeService";
import {useAuth0} from "@auth0/auth0-react";
import recipeEditForm from "../components/RecipeEditForm/RecipeEditForm";

export default function EditRecipePage() {

    const [getRecipe, setRecipe] = useState({});
    const [isLoading, setLoading] = useState(true);
    const token = useRef("");
    let params = useParams();
    const {getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        setLoading(true);
        getAccessTokenSilently().then(result => {
            token.current = result;
            const service = new RecipeService();
            service.getRecipeById(params.id, token.current).then(response => {
                if(response.status === 200) {
                    response.json().then(recipe => {
                        setRecipe(recipe);
                        setLoading(false);
                    });
                } else {
                    window.location.href = "/";
                }
            });
        });
    }, []);

    if(isLoading) {
        return (<></>);
    } else {
        return (
            <div className={"main-container"}>
                <div className={"menu-container"}>
                    <LeftMenu/>
                </div>

                <div className={"container-item"}>
                    <PageTitle
                        title={"Edit " + getRecipe.title}
                        icon={<PlusCircle className={styles.TitleIcon} size={30} weight={"bold"}/>}
                        controls={""}
                    />
                    <RecipeEditForm recipe={getRecipe}/>
                </div>
            </div>
        );
    }

}