import React from 'react';
import {useFilter} from "../../FilterContext/FilterContext";
import Dropdown from "../../Forms/Dropdown/Dropdown";

const TimeFilterComponent: React.FC<{options: [string]}> = (props) => {

    const { filters, setFilters } = useFilter();

    const convertToISO8601Duration = (duration: string): string => {
        switch (duration) {
            case "< 20 minutes":
                return "PT20M";
            case "< 30 minutes":
                return "PT30M";
            case "< 1 hour":
                return "PT1H";
            case "< 2 hours":
                return "PT2H";
            default:
                return ""; // Return empty string if duration is not recognized
        }
    };

    const handleCheckboxFilterChange = (filterName: string, value: any, e: React.ChangeEvent<HTMLInputElement>) => {
        const isoDuration = convertToISO8601Duration(value);
        if (e.target.checked) {
            setFilters({ ...filters, [filterName]: [...(filters[filterName] || []), isoDuration] });
        } else {
            const updatedValues = (filters[filterName] || []).filter((item: any) => item !== isoDuration);
            setFilters({ ...filters, [filterName]: updatedValues });
        }
    };

    return (
        <>
            <div className={"max-sm:w-full"}>
                <Dropdown
                    title={"Time duration"}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>, time: string) => {
                        handleCheckboxFilterChange("time", time, e);
                    }}
                    optionsArray={props.options.map(time => {
                        return time
                    })}
                />
            </div>
        </>
    );
};

export default TimeFilterComponent;
